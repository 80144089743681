<template>
  <b-card title="Valve" sub-title="Information about valve">
    <b-row>
      <b-col md="6">
        <b-card class="bordered-card" title="TV disease">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="TV Disease"
                label-for="tv_disease"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.tv_disease"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Stenosis"
                label-for="stenosis"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.tv_stenosis"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group
                label="Regurgitation"
                label-for="regurgitation"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.tv_regurgitation"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="TR grade"
                label-for="tr_grade"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.tv_disease_regurgitation_tr_grade"
                  id="tr_grade"
                  type="number"
                  placeholder="TR grade"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group
                label="TR etiology"
                label-for="tv_tr_etiology"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.tv_tr_etiology"
                  label="value"
                  :options="options1"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group
                label="TV annulus > 40mm"
                label-for="tv_annulus_gt_40mm"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.tv_annulus_gt_40mm"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group
                label="Endocarditis"
                label-for="tv_endocarditis"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.tv_endocarditis"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group
                label="Type"
                label-for="tv_disease_endocarditis_type"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.tv_disease_endocarditis_type"
                  label="value"
                  :options="options2"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card class="bordered-card" title="MV disease">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="MV Disease"
                label-for="mv_disease"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.mv_disease"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Stenosis"
                label-for="stenosis"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.mv_stenosis"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group
                label="Regurgitation"
                label-for="regurgitation"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.mv_regurgitation"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="MR grade"
                label-for="mr_grade"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.mv_disease_regurgitation_mr_grade"
                  id="mr_grade"
                  type="number"
                  placeholder="MR grade"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group
                label="MR etiology"
                label-for="mr_etiology"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.mr_etiology"
                  label="value"
                  :options="options1"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group
                label="MV annulus > 40mm"
                label-for="mv_annulus"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.mv_annulus"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group
                label="MV Endocarditis"
                label-for="mv_endocarditis"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.mv_disease_endocarditis"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group label="Type" label-for="type" label-cols-md="4">
                <v-select
                  v-model="patientInfo.mv_disease_endocarditis_type"
                  label="value"
                  :options="options2"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        tv_disease: "",
        tv_stenosis: "",
        tv_regurgitation: "",
        tv_disease_regurgitation_tr_grade: 0,
        tv_tr_etiology: "",
        tv_annulus_gt_40mm: "",
        tv_endocarditis: "",
        tv_disease_endocarditis_type: "",
        mv_disease: "",
        mv_stenosis: "",
        mv_regurgitation: "",
        mv_disease_endocarditis: "",
        mv_disease_endocarditis_type: "",
        mv_disease_regurgitation_mr_grade: 0,
        mr_etiology: "",
        mv_annulus: "",
      },
      options: ["yes", "no"],
      options1: ["primary", "secondary"],
      options2: ["active", "status post"],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    setPatientInfo(v) {
      this.patientInfo._id = v._id;
      this.patientInfo.tv_disease = v.tv_disease;
      this.patientInfo.tv_stenosis = v.tv_stenosis;
      this.patientInfo.tv_regurgitation = v.tv_regurgitation;
      this.patientInfo.tv_disease_regurgitation_tr_grade =
        v.tv_disease_regurgitation_tr_grade;
      this.patientInfo.tv_tr_etiology = v.tv_tr_etiology;
      this.patientInfo.tv_annulus_gt_40mm = v.tv_annulus_gt_40mm;
      this.patientInfo.tv_endocarditis = v.tv_endocarditis;
      this.patientInfo.tv_disease_endocarditis_type =
        v.tv_disease_endocarditis_type;
      this.patientInfo.mv_disease = v.mv_disease;
      this.patientInfo.mv_stenosis = v.mv_stenosis;
      this.patientInfo.mv_regurgitation = v.mv_regurgitation;
      this.patientInfo.mv_disease_endocarditis = v.mv_disease_endocarditis;
      this.patientInfo.mv_disease_endocarditis_type =
        v.mv_disease_endocarditis_type;
      this.patientInfo.mv_disease_regurgitation_mr_grade =
        v.mv_disease_regurgitation_mr_grade;
      this.patientInfo.mr_etiology = v.mr_etiology;
      this.patientInfo.mv_annulus = v.mv_annulus;
    },
  },
};
</script>

<style>
</style>
