<template>
  <b-row class="match-height preop-tab">
    <b-col md="6">
      <previous ref="previous"></previous>
    </b-col>
    <b-col md="6">
      <valve ref="valve"></valve>
    </b-col>
    <b-col cols="12">
      <echo ref="echo"></echo>
    </b-col>
    <b-col md="6">
      <laboratory ref="laboratory"></laboratory>
    </b-col>
    <b-col md="6">
      <clinical ref="clinical"></clinical>
    </b-col>
    <b-col cols="12">
      <status ref="status"></status>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import Previous from "./preop/Previous";
import Valve from "./preop/Valve";
import Echo from "./preop/Echo";
import Laboratory from "./preop/Laboratory";
import Clinical from "./preop/Clinical";
import Status from "./preop/Status";
import { mapState, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import config from "@/config";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Previous,
    Valve,
    Echo,
    Laboratory,
    Clinical,
    Status,
  },
  timers: { updatePatient: config.TIMER_OPTION },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  methods: {
    ...mapActions("patient", ["updatePatientById"]),
    updatePatient() {
      if (this.readOnly) {
        return;
      }

      let activeElement = document.activeElement;
      let blockingElements = ["input", "textarea"];
      let isFlatPickrInput =
        activeElement && activeElement.classList.contains("flatpickr-input");

      // check whether an input field is the active elemnent,
      //if it is, do not interrupt the users entries with updating
      // the patientInfo but return instead
      if (
        activeElement &&
        blockingElements.indexOf(activeElement.tagName.toLowerCase()) !== -1 &&
        !isFlatPickrInput // still save, if the active element is a flatpickr element and thus a date field
      ) {
        return;
      }

      let patientInfo = {
        ...this.$refs.previous.patientInfo,
        ...this.$refs.valve.patientInfo,
        ...this.$refs.echo.patientInfo,
        ...this.$refs.laboratory.patientInfo,
        ...this.$refs.clinical.patientInfo,
        ...this.$refs.status.patientInfo,
      };

      this.updatePatientById(patientInfo).catch((err) => {
        this.showToast(
          "danger",
          "Error",
          "An error occured while updating the patient."
        );
      });
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.preop-tab {
  .vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .bordered-card {
    border: 1px solid rgba(34, 41, 47, 0.125);
  }
}
</style>
