<template>
  <b-card title="Status" sub-title="Information about status">
    <b-row>
      <b-col md="4">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Inotropes"
              label-for="status_preop_inotropes"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_inotropes"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-card class="bordered-card" title="CAD">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="CAD"
                    label-for="status_preop_cad"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.status_preop_cad"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="mt-2">
                  <b-form-group
                    label="Previous PCI"
                    label-for="status_preop_previous_pci"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.status_preop_previous_pci"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Date"
                    label-for="v-birth"
                    label-cols-md="4"
                  >
                    <b-input-group class="mb-1">
                      <b-form-input
                        id="example-input"
                        v-model="patientInfo.status_preop_cad_date"
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date1"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Interval:"
                    label-for="interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{ patientInfo.status_preop_cad_interval }}
                    </div>
                  </b-form-group>
                </b-col>

                <b-col cols="12" class="mt-2">
                  <b-form-group
                    label="Previous CABG"
                    label-for="status_preop_previous_cabg"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.status_preop_previous_cabg"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Date"
                    label-for="v-birth"
                    label-cols-md="4"
                  >
                    <b-input-group class="mb-1">
                      <b-form-input
                        id="example-input"
                        v-model="patientInfo.status_preop_cabg_date"
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date2"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Interval:"
                    label-for="interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{ patientInfo.status_preop_CAD_previous_CABG_interval }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col cols="12" class="mt-2">
            <b-form-group label="COPD" label-for="copd" label-cols-md="4">
              <v-select
                v-model="patientInfo.status_preop_copd"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="COPD gold stage"
              label-for="status_preop_copd_gold_stage"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_copd_gold_stage"
                label="value"
                :options="options3"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Urgency"
              label-for="status_preop_urgency"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_urgency"
                label="value"
                :options="options4"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Stroke"
              label-for="status_preop_stroke"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_stroke"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Dialysis"
              label-for="status_preop_dialysis"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_dialysis"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Myocardial infarction"
              label-for="status_preop_myocardial_infarction"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_myocardial_infarction"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Rhythm"
              label-for="status_preop_rhythm"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_rhythm"
                label="value"
                :options="options5"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Atrial septal defect"
              label-for="status_preop_atrial_septal_defect"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_atrial_septal_defect"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Renal failure grade"
              label-for="renal_failure"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_renal_failure_grade"
                label="value"
                :options="options6"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Arterial hypertension"
              label-for="status_preop_arterial_hypertension"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_arterial_hypertension"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Diabetes"
              label-for="status_preop_diabetes"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_diabetes"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Peripheral arterial disease"
              label-for="status_preop_peripheral_arterial_disease"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_peripheral_arterial_disease"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Atrial fibrillation"
              label-for="status_preop_atrial_fibrillation"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_atrial_fibrillation"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Atrial Fibrillation Type"
              label-for="atrial_fibrillation_type"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.preop_rythm_atrial_fibrillation"
                :options="optionsAtrialFibrillationType"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="PreOP pacemaker"
              label-for="status_preop_preop_pacemaker"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_preop_pacemaker"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <b-card class="bordered-card" title="Right heart failure">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Right heart failure"
                label-for="status_preop_right_heart_failure"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.status_preop_right_heart_failure"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Odema" label-for="odema" label-cols-md="4">
                <v-select
                  v-model="patientInfo.status_preop_right_heart_failure_odema"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Ascites"
                label-for="ascites"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.status_preop_right_heart_failure_ascites"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Venous congestion"
                label-for="venous"
                label-cols-md="4"
              >
                <v-select
                  v-model="
                    patientInfo.status_preop_right_heart_failure_venous_congestion
                  "
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Pleural effusion"
                label-for="pleural effusion"
                label-cols-md="4"
              >
                <v-select
                  v-model="
                    patientInfo.status_preop_right_heart_failure_pleural_effusion
                  "
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Failure Grade"
                label-for="right_heart_failure_grade"
                label-cols-md="4"
              >
                <v-select
                  v-model="
                    patientInfo.preop_right_heart_failure_right_heart_failure_grade
                  "
                  :options="optionsRightHeartFailureGrade"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="bordered-card" title="Inflammatory status">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Inflammatory status"
                label-for="status_preop_inflammatory_status"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.status_preop_inflammatory_status"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Focus"
                label-for="status_preop_focus"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.status_preop_focus"
                  id="focus"
                  type="text"
                  placeholder="Focus"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { convertDate2Str, dateMask, subtractDate } from "@core/utils/utils";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        status_preop_inotropes: "",
        status_preop_cad: "",
        status_preop_previous_pci: "",
        status_preop_cad_date: "",
        status_preop_cad_interval: "",
        status_preop_previous_cabg: "",
        status_preop_cabg_date: "",
        status_preop_CAD_previous_CABG_interval: "",
        status_preop_copd: "",
        status_preop_copd_gold_stage: "",
        status_preop_urgency: "",
        status_preop_stroke: "",
        status_preop_dialysis: "",
        status_preop_myocardial_infarction: "",
        status_preop_rhythm: "",
        status_preop_atrial_septal_defect: "",
        status_preop_right_heart_failure: "",
        status_preop_right_heart_failure_odema: "",
        status_preop_right_heart_failure_ascites: "",
        status_preop_right_heart_failure_venous_congestion: "",
        status_preop_right_heart_failure_pleural_effusion: "",
        preop_right_heart_failure_right_heart_failure_grade: "",
        preop_rythm_atrial_fibrillation: "",
        status_preop_arterial_hypertension: "",
        status_preop_diabetes: "",
        status_preop_peripheral_arterial_disease: "",
        status_preop_atrial_fibrillation: "",
        status_preop_inflammatory_status: "",
        status_preop_focus: "",
        status_preop_preop_pacemaker: "",
        status_preop_renal_failure_grade: "",
      },
      value: ", ",
      options: ["yes", "no"],
      v1: ", ",
      v2: ", ",
      date1: null,
      date2: null,
      options: ["yes", "no"],
      options1: ["primary", "secondary"],
      options2: ["active", "status post"],
      options3: [
        "I FEV1 % vom Soll: ≥ 80%",
        "II FEV1 % vom Soll: 50-79%",
        "III FEV1 % vom Soll: 30-49%",
        "IV FEV1 % vom Soll: <30 %",
      ],
      options4: ["elective", "urgent", "emergent", "salvage"],
      options5: [
        "sinus rhythm",
        "AV block II",
        "AV block III",
        "A fib",
        "sick sinus",
      ],
      options6: [
        "normal GFR<85",
        "severe GFR<50",
        "moderate GFR 50-85",
        "on dialysis",
      ],
      optionsRightHeartFailureGrade: ["1", "2", "3", "4", "5"],
      optionsAtrialFibrillationType: [
        "Long lasting",
        "Paroxysmal",
        "Persistent",
        "Permanent",
      ],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == ", ";
    },
    status_preop_cad_interval() {
      return subtractDate(
        this.patientInfo.status_preop_cad_date,
        this.patient.procedure_date,
        "day"
      );
    },
    status_preop_CAD_previous_CABG_interval() {
      return subtractDate(
        this.patientInfo.status_preop_cabg_date,
        this.patient.procedure_date,
        "day"
      );
    },
  },
  watch: {
    date1(v, ov) {
      if (v) {
        this.patientInfo.status_preop_cad_date = convertDate2Str(v);
      }
    },
    date2(v, ov) {
      if (v) {
        this.patientInfo.status_preop_cabg_date = convertDate2Str(v);
      }
    },
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
    status_preop_cad_interval(v, ov) {
      this.patientInfo.status_preop_cad_interval = v;
    },
    status_preop_CAD_previous_CABG_interval(v, ov) {
      this.patientInfo.status_preop_CAD_previous_CABG_interval = v;
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;
      this.patientInfo.status_preop_inotropes = v.status_preop_inotropes;
      this.patientInfo.status_preop_cad = v.status_preop_cad;
      this.patientInfo.status_preop_previous_pci = v.status_preop_previous_pci;
      this.patientInfo.status_preop_cad_date = v.status_preop_cad_date;
      this.patientInfo.status_preop_cad_interval = v.status_preop_cad_interval;
      this.patientInfo.status_preop_previous_cabg =
        v.status_preop_previous_cabg;
      this.patientInfo.status_preop_cabg_date = v.status_preop_cabg_date;
      this.patientInfo.status_preop_CAD_previous_CABG_interval =
        v.status_preop_CAD_previous_CABG_interval;
      this.patientInfo.status_preop_copd = v.status_preop_copd;
      this.patientInfo.status_preop_copd_gold_stage =
        v.status_preop_copd_gold_stage;
      this.patientInfo.status_preop_urgency = v.status_preop_urgency;
      this.patientInfo.status_preop_stroke = v.status_preop_stroke;
      this.patientInfo.status_preop_dialysis = v.status_preop_dialysis;
      this.patientInfo.status_preop_myocardial_infarction =
        v.status_preop_myocardial_infarction;
      this.patientInfo.status_preop_rhythm = v.status_preop_rhythm;
      this.patientInfo.status_preop_atrial_septal_defect =
        v.status_preop_atrial_septal_defect;
      this.patientInfo.status_preop_right_heart_failure =
        v.status_preop_right_heart_failure;
      this.patientInfo.status_preop_right_heart_failure_odema =
        v.status_preop_right_heart_failure_odema;
      this.patientInfo.status_preop_right_heart_failure_ascites =
        v.status_preop_right_heart_failure_ascites;
      this.patientInfo.status_preop_right_heart_failure_venous_congestion =
        v.status_preop_right_heart_failure_venous_congestion;
      this.patientInfo.status_preop_right_heart_failure_pleural_effusion =
        v.status_preop_right_heart_failure_pleural_effusion;
      this.patientInfo.preop_right_heart_failure_right_heart_failure_grade =
        v.preop_right_heart_failure_right_heart_failure_grade;
      this.patientInfo.preop_rythm_atrial_fibrillation =
        v.preop_rythm_atrial_fibrillation;
      this.patientInfo.status_preop_arterial_hypertension =
        v.status_preop_arterial_hypertension;
      this.patientInfo.status_preop_diabetes = v.status_preop_diabetes;
      this.patientInfo.status_preop_peripheral_arterial_disease =
        v.status_preop_peripheral_arterial_disease;
      this.patientInfo.status_preop_atrial_fibrillation =
        v.status_preop_atrial_fibrillation;
      this.patientInfo.status_preop_inflammatory_status =
        v.status_preop_inflammatory_status;
      this.patientInfo.status_preop_focus = v.status_preop_focus;
      this.patientInfo.status_preop_preop_pacemaker =
        v.status_preop_preop_pacemaker;
      this.patientInfo.status_preop_renal_failure_grade =
        v.status_preop_renal_failure_grade;
    },
  },
};
</script>

<style>
</style>